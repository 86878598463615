import { createTheme } from '@mui/material';
import type { LinkProps, ThemeOptions } from '@mui/material';
import type { LinkProps as RouterLinkProps } from '@remix-run/react';
import { Link as RouterLink } from '@remix-run/react';
import * as React from 'react';
import { COLORS } from '@tbd/life-tokens';

export const LIFT_COLORS_LIGHT = {
  primary: COLORS.accent1_500, // left this as accent1_500 to match status quo, but should confirm/revisit
  secondary: COLORS.accent2,
  text: COLORS.black,
  error: COLORS.critical,
  warning: COLORS.warning,
  success: COLORS.correct,
} as const;

export const LIFT_COLORS_DARK = {
  primary: COLORS.accent1_200,
  text: COLORS.white,
  critical: COLORS.critical_300,
  warning: COLORS.warning,
  success: COLORS.correct,
} as const;

// from https://github.com/mui/material-ui/blob/master/packages/mui-material/src/styles/zIndex.js
// can't find a way to import this from MUI (at least not in a way compatible with Vite)
export const zIndex = {
  mobileStepper: 1000,
  fab: 1050,
  speedDial: 1050,
  appBar: 1100,
  drawer: 1200,
  modal: 1300,
  snackbar: 1400,
  tooltip: 1500,
};

export const LinkBehavior = React.forwardRef<
  HTMLAnchorElement,
  Omit<RouterLinkProps, 'to'> & { href: RouterLinkProps['to'] }
>((props, ref) => {
  const { href, ...other } = props;
  // Map href (MUI) -> to (react-router)
  return <RouterLink ref={ref} to={href} {...other} />;
});

// Create and export types for MUI
//
// We must use `declare module ... ` and `interface`s to augment the MUI types
// however, if you `import type TypographyVariants from '@mui/material/styles'
// it uses this declaration which is only a declaration and doesn't export the types
// Also, you cannot get from a runtime value
// `const value = { some: 'thing' }`
// to an `interface`; only a `type` using `typeof value`
// but we have an `interface` that `extends` a `type`
// so we
// A) create runtime values, and use `as const`
// B) get the types using `typeof value`
// C) make the required MUI `interface`s by `extend`ing the `type`s from B

///////////////////////
// A) runtime values //
///////////////////////
const components: ThemeOptions['components'] = {
  MuiLink: {
    defaultProps: {
      component: LinkBehavior,
    } as LinkProps,
  },
  MuiButtonBase: {
    defaultProps: {
      LinkComponent: LinkBehavior,
    },
  },
} as const;

const breakpoints = {
  values: {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
    xxl: 1440,
  },
} as const;

const palette: ThemeOptions['palette'] = {
  mode: 'light',
  primary: {
    main: LIFT_COLORS_LIGHT.primary,
  },
  secondary: {
    main: LIFT_COLORS_LIGHT.secondary,
  },
  text: {
    primary: LIFT_COLORS_LIGHT.text,
  },
  error: {
    main: LIFT_COLORS_LIGHT.error,
  },
  warning: {
    main: LIFT_COLORS_LIGHT.warning,
  },
  success: {
    main: LIFT_COLORS_LIGHT.success,
  },
} as const;

const headerTypographyVariants = {
  'header-xl': {
    fontSize: 48,
    fontWeight: 700,
    lineHeight: '64px',
  },
  'header-l': {
    fontSize: 40,
    fontWeight: 700,
    lineHeight: '52px',
  },
  'header-m': {
    fontSize: 32,
    fontWeight: 700,
    lineHeight: '40px',
  },
  'header-s': {
    fontSize: 28,
    fontWeight: 700,
    lineHeight: '36px',
  },
  'header-xs': {
    fontSize: 24,
    fontWeight: 700,
    lineHeight: '32px',
  },
  'header-xxs': {
    fontSize: 20,
    fontWeight: 700,
    lineHeight: '24px',
  },
} as const;

const paragraphTypographyVariants = {
  'paragraph-xl': {
    fontSize: 24,
    fontWeight: 400,
    lineHeight: '36px',
  },
  'paragraph-l': {
    fontSize: 20,
    fontWeight: 400,
    lineHeight: '32px',
  },
  'paragraph-m': {
    fontSize: 18,
    fontWeight: 400,
    lineHeight: '28px',
  },
  'paragraph-s': {
    fontSize: 16,
    fontWeight: 400,
    lineHeight: '24px',
  },
  'paragraph-xs': {
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '20px',
  },
  'paragraph-xl-bold': {
    fontSize: 24,
    fontWeight: 700,
    lineHeight: '36px',
  },
  'paragraph-l-bold': {
    fontSize: 20,
    fontWeight: 700,
    lineHeight: '32px',
  },
  'paragraph-m-bold': {
    fontSize: 18,
    fontWeight: 700,
    lineHeight: '28px',
  },
  'paragraph-s-bold': {
    fontSize: 16,
    fontWeight: 700,
    lineHeight: '24px',
  },
  'paragraph-xs-bold': {
    fontSize: 14,
    fontWeight: 700,
    lineHeight: '20px',
  },
} as const;

const componentTypographyVariants = {
  'component-regular-xl': {
    fontSize: 24,
    fontWeight: 400,
    lineHeight: '28px',
  },
  'component-regular-l': {
    fontSize: 20,
    fontWeight: 400,
    lineHeight: '24px',
  },
  'component-regular-m': {
    fontSize: 18,
    fontWeight: 400,
    lineHeight: '24px',
  },
  'component-regular-s': {
    fontSize: 16,
    fontWeight: 400,
    lineHeight: '20px',
  },
  'component-regular-xs': {
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '16px',
  },
  'component-regular-xxs': {
    fontSize: 12,
    fontWeight: 400,
    lineHeight: '16px',
  },
  'component-bold-xl': {
    fontSize: 24,
    fontWeight: 700,
    lineHeight: '28px',
  },
  'component-bold-l': {
    fontSize: 20,
    fontWeight: 700,
    lineHeight: '24px',
  },
  'component-bold-m': {
    fontSize: 18,
    fontWeight: 700,
    lineHeight: '24px',
  },
  'component-bold-s': {
    fontSize: 16,
    fontWeight: 700,
    lineHeight: '20px',
  },
  'component-bold-xs': {
    fontSize: 14,
    fontWeight: 700,
    lineHeight: '16px',
  },
  'component-bold-xxs': {
    fontSize: 12,
    fontWeight: 700,
    lineHeight: '16px',
  },
  'component-italic-xl': {
    fontSize: 24,
    fontStyle: 'italic',
    fontWeight: 400,
    lineHeight: '28px',
  },
  'component-italic-l': {
    fontSize: 20,
    fontStyle: 'italic',
    fontWeight: 400,
    lineHeight: '24px',
  },
  'component-italic-m': {
    fontSize: 18,
    fontStyle: 'italic',
    fontWeight: 400,
    lineHeight: '24px',
  },
  'component-italic-s': {
    fontSize: 16,
    fontStyle: 'italic',
    fontWeight: 400,
    lineHeight: '20px',
  },
  'component-italic-xs': {
    fontSize: 14,
    fontStyle: 'italic',
    fontWeight: 400,
    lineHeight: '16px',
  },
  'component-italic-xxs': {
    fontSize: 12,
    fontStyle: 'italic',
    fontWeight: 400,
    lineHeight: '16px',
  },
  'component-uppercase-bold-xs': {
    fontSize: 14,
    fontWeight: 700,
    lineHeight: '16px',
    textTransform: 'uppercase',
  },
  'component-uppercase-bold-xxs': {
    fontSize: 12,
    fontWeight: 700,
    lineHeight: '16px',
    textTransform: 'uppercase',
  },
} as const;

const typographyVariants = {
  ...headerTypographyVariants,
  ...paragraphTypographyVariants,
  ...componentTypographyVariants,
} as const;

const typography: ThemeOptions['typography'] = {
  fontFamily: 'Lato, sans-serif',
  button: {
    textTransform: 'none',
  },
  ...typographyVariants,
} as const;

//////////////////////////////////
// B) types from runtime values //
//////////////////////////////////
type AdditionalTypographyVariants = Record<
  keyof typeof typographyVariants,
  true
>;
type DisabledTypographyVariants = Record<
  'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6',
  false
>;

export type LiftTypographyVariants = typeof typographyVariants;
export type LiftTypographyVariantsOptions = typeof typographyVariants;
export interface LiftTypographyPropsVariantOverrides
  extends DisabledTypographyVariants,
    AdditionalTypographyVariants {}

/////////////////////////////////////////////////
// C) export MUI interfaces using types from B //
/////////////////////////////////////////////////
declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xxl: true; // adds the `xxl` breakpoint
  }

  // *must* be `interface` not `type` to work with MUI
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface TypographyVariants extends LiftTypographyVariants {}

  // *must* be `interface` not `type` to work with MUI
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface TypographyVariantsOptions extends LiftTypographyVariantsOptions {}
}

declare module '@mui/material/Typography' {
  // *must* be `interface` not `type` to work with MUI
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface TypographyPropsVariantOverrides
    extends LiftTypographyPropsVariantOverrides {}
}

export const themeOptions: ThemeOptions = {
  breakpoints,
  components,
  palette,
  typography,
};

export const theme = createTheme(themeOptions);
